import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  standalone: true,
  selector: `button[loading]`
})
export class LoadButtonDirective implements OnChanges {
  @Input() loading: boolean;

  constructor(private matButton: ElementRef) {
  }

  get nativeElement(): HTMLElement {
    return this.matButton.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes.loading) {
      return;
    }

    if (changes.loading.currentValue) {

      this.nativeElement.classList.add('btn-loading');
      this.matButton.nativeElement.disabled = true;
      this.matButton.nativeElement.setAttribute('data-disabled', 'true');

    } else if (!changes.loading.currentValue && changes.loading.previousValue) {
      this.nativeElement.classList.remove('btn-loading');
      this.matButton.nativeElement.disabled = false;
      this.matButton.nativeElement.setAttribute('data-disabled', 'false');

    }

  }

}
