<div class=" rounded-xl flex h-[150px]  hover:shadow-xl cursor-pointer" (click)="portSelected()">
  <div [ngStyle]="{backgroundImage:'url(' + port.bgImageUrl + ')'  }" class="basis-1/2 bg-cover rounded-l-xl">

  </div>
  <div class="flex flex-col   justify-between pt-3 pl-5 pr-5">
    <div>
      <div class="text-gray-500 mb-1">Porto di</div>
      <div class="font-bold text-lg">{{ port.name | titlecase }}</div>
    </div>
    @if (statistics| async; as stat) {
      <div class="mb-2">
        <div class="text-sm text-gray-700 ">Posti marina disponibili: <span
          class="font-bold ">{{ stat[1] === 0 ? 0 : stat[1] - stat[0] }}</span></div>
        <div class="text-sm text-gray-700 ">Posti marina totali: <span class="font-bold">{{ stat[1] }}</span></div>
      </div>
    }
  </div>
</div>
