<div class="p-4 space-y-3">

  <h4>Avviso cancellazione</h4>
  <div>
    Vuoi procedere con l'eliminazione del file <b>{{ this.data.attachment.name }}</b>?
    <br>
    Se confermi il file verrà definitivamente eliminato e non sarà più possibile recuperarlo.
  </div>
  <div class="flex flex-row justify-end space-x-4">
    <button (click)="close()" class="btn btn-light" type="button">
      <span class="pl-6 pr-6">Annulla</span>
    </button>
    <button (click)="remove()" class="btn btn-danger" type="button">
      <span class="pl-6 pr-6">Si, rimuovi</span>

    </button>
  </div>
</div>
