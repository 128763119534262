import {Apollo, gql, MutationResult} from "apollo-angular";
import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, filter, map, Observable, Subject, takeUntil, tap} from "rxjs";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {BerthDTO, BoatDTO} from "./dto";

export const BERTH_FIELD = gql`
  fragment BERTH_FIELD on BerthDTO {
    id
    code
    length
    portId
  }
`;


const add = gql`
  ${BERTH_FIELD}

  mutation AddBerth($dto: BerthDTO!) {
    berth_add_one(body: $dto) {
      ...BERTH_FIELD
    }
  }`
const update = gql`
  ${BERTH_FIELD}

  mutation UpdateBerth($id: Int!, $dto: BoatDTO!) {
    berth_update_one(pk_columns: {id: $id}, body: $dto){
      ...BERTH_FIELD

    }

  }`


const getAll = gql`
  ${BERTH_FIELD}

  query GetAllBerths($pageNumber: Int, $pageDim: Int, $where:String) {
    berth (pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...BERTH_FIELD

    }
  }
`;

const getById = gql`
  ${BERTH_FIELD}

  query GetBerthById($id: ID!) {
    berth_by_pk(id: $id) {
      ...BERTH_FIELD

    }
  }`;


const count = gql`query CountBerth( $where: String ) {
  berth_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;

const getAllLengths = gql`
  query GetAllLengths {
    berth(distinct: true, sort: "length") {
    length
  }
}
`;

const getBerthsByLength = gql`
  query GetBerthsByLength($length: Float!) {
    berth(distinct: false, pageDim: 1000, sort: "", pageNum: 0, where: $where) {
    id
  }
  }
`;



@Injectable({
  providedIn: 'root'
})
export class BerthService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation, includes?: { port: boolean }): Observable<{
    data: BerthDTO[],
    totalRows: number
  }> {
    const inc = {
      ...{port: false},
      ...includes
    }
    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['berth_aggregate']
        // @ts-ignore
        const data = x[0].data?.['berth']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes?: { port: boolean }): Observable<BoatDTO> {
    const inc = {
      ...{port: false},
      ...includes
    }
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includePort: inc.port
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['berth_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  count(filters?: BoolOperation): Observable<number> {
    return this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(
        filter(c => !c.loading),
        map(x => {
            // @ts-ignore
            const aggRes = x.data['berth_aggregate']
            return aggRes.count
          }
        )
      )
  }

  add(dto: BoatDTO): Observable<BoatDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.insert_company_one))
  }

  update(id: number, dto: BoatDTO): Observable<BoatDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.update_company_by_pk))
  }

  getUniqueLengths(): Observable<number[]> {
    return this.apollo.watchQuery<{ berth: { length: number }[] }>({
      query: getAllLengths,
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      tap(x => {
        console.log('Raw data from getAllLengths query:', x);
      }),
      map(x => {
        const data = x.data?.berth;
        const lengths = data.map((y: { length: number }) => y.length);
        const uniqueLengths = Array.from(new Set(lengths)); // Rimuove duplicati
        console.log('Unique lengths:', uniqueLengths);
        return uniqueLengths;
      })
    );
  }

  getBerthsByLength(length: number): Observable<number[]> {
    return this.apollo.watchQuery<{ berth: { id: number }[] }>({
      query: getBerthsByLength,
      variables: { where: `length == ${length}` },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      tap(x => {
        console.log(`Raw data from getBerthsByLength query for length ${length}:`, x);
      }),
      map(x => {
        const data = x.data?.berth;
        const ids = data.map((y: { id: number }) => y.id);
        console.log(`Berths with length ${length}:`, ids);
        return ids;
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
