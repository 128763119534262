import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PortDTO} from "../../service/dto";
import {ReservationService} from "../../service/reservation.service";
import {forkJoin, Observable, take} from "rxjs";
import {ExpressionBuilder} from "../../service/ExpressionBuilder";
import moment from "moment/moment";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {BerthService} from "../../service/berth.service";

@Component({
  selector: 'app-port-card',
  templateUrl: './port-card.component.html',
  styleUrl: './port-card.component.scss'
})
export class PortCardComponent implements OnInit {
  @Input()
  port!: PortDTO
  @Output()
  onPortClick = new EventEmitter<PortDTO>()

  statistics: Observable<[number, number]>;


  constructor(private reservationService: ReservationService,
              private berthService: BerthService,
              private toastService: ToastrService,) {
  }

  ngOnInit(): void {
    const now = moment(new Date());
    let exp = ExpressionBuilder.getBuilder().root().eq('berth.port.id', this.port.id)
    exp = exp.or()
    exp = exp.gte('fromDate', now.startOf('day').toISOString()).lte('fromDate', now.endOf('day').toISOString())
    exp = exp.gte('toDate', now.startOf('day').toISOString()).lte('toDate', now.endOf('day').toISOString())
    exp = exp.and().lte('fromDate', now.startOf('day').toISOString()).gte('toDate', now.endOf('day').toISOString())


    const countReservation = (f) => this.reservationService.count(f).pipe(take(1))

    let root2 = ExpressionBuilder.getBuilder().root().eq('port.id', this.port.id)
    const countBerths = f => this.berthService.count(f).pipe(take(1));

    this.statistics = forkJoin([countReservation(exp), countBerths(root2)]).pipe(
      catchError(e => {
        this.toastService.error("Errore durante il recupero dei dati")
        throw e
      }),
    )
  }

  portSelected() {
    this.onPortClick.emit(this.port);
  }
}
